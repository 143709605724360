import  * as React from 'react';
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {fadeInDownStyle,fadeInLeftStyle,fadeInRigthStyle} from "../../styles/style";


const Hero = (props) => {
    return (
<section id="home" className="hero-section">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6">
                <div className="hero-content-wrapper">
                    <h2 className="mb-25 wow fadeInDown" data-wow-delay=".2s" style={fadeInDownStyle}>SERVIZI INTEGRATI</h2>
                    <h1 className="mb-25 wow fadeInDown" data-wow-delay=".2s" style={fadeInDownStyle}>Pulizie e Sanificazioni Civili ed Industriali</h1>
                    <p className="mb-35 wow fadeInLeft" data-wow-delay=".4s" style={fadeInLeftStyle}>Da più di 30 Anni, Ci occupiamo di Pulizie di Uffici, Condomini, Negozi, Palestre 
                        e Studi Medici a Milano e Provincia. MANUTENPUL Garantisce Serietà, Professionalità e rapidità!</p>
                        <div className="section-title text-center mb-10">
                        <p>Chiama ora e prenota il servizio adatto a te! <strong><a style={{"color":"blue"}} href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
                        <Link to="/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
                        </div>
                </div>
            </div>
            <div className="col-xl-7 col-lg-6">
                <div className="hero-img">
                    <div className="d-inline-block hero-img-right">
                        <StaticImage src="../../images/car2_2.webp" alt="hero_back" className="image wow fadeInRight" data-wow-delay=".5s" style={fadeInRigthStyle} />
                        <div className="video-btn">
                            <a href="https://fb.watch/8y91xoCxZH/" className="glightbox"><span className="material-icons">play_circle</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)};

export default Hero