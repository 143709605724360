import { fadeInDownStyle,fadeInUpStyle,fadeInRigthStyle } from "../styles/style";
import Swal from "sweetalert2";
import * as React from "react";
import axios from "axios";
import { Link } from "gatsby";

const sectionStyle = {
  background: "linear-gradient(to left, #2e88a2 0%, #88ddf5 50.39%, #3763eb 100%)",
};

const handleSubmit = event =>{
  event.preventDefault();
  if(!document.getElementById("consenso").checked){
    Swal.fire({
      icon: 'info',
      title: 'Oops...',
      text: 'accettare le privacy policy',
    });
    return false;
  }
  let formData = new FormData(event.target);
  axios.post(
    'https://erp.manutenpul.it/subscribe/me', 
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
      responseType: 'json',
    }).then((response)=>{
      Swal.fire(
        'Grazie!',
        'Ti risponderemo il prima possibile',
        'success'
      ).then((success)=>{
        window.location.reload();
      });
    }).catch((error)=>{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'compilando il form',
      });
    });
};

const Subscribe = () => {
  return (
    <section
      className="subscribe-section pt-30 pb-30 img-bg"
      style={sectionStyle}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6">
            <div className="section-title mb-30">
              <span
                className="text-white wow fadeInDown"
                data-wow-delay=".2s"
                style={fadeInDownStyle}
              >
                Iscriviti alla 
              </span>
              <h2
                className="text-white mb-30 wow fadeInUp"
                data-wow-delay=".4s"
                style={fadeInUpStyle}
              >
                Nostra Newsletter
              </h2>
              <input type="checkbox" name="consenso" aria-invalid="false" id="consenso" className="checkbox-input" />
              <label htmlFor="consenso">
                <span className="text-white wow fadeInDown"
                data-wow-delay=".2s"
                style={fadeInDownStyle}>
                Ho letto ed accetto la  <Link to="/privacy-policy">Privacy Policy*</Link>
              </span>
              </label>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <form
              onSubmit={handleSubmit} 
              className="subscribe-form wow fadeInRight"
              data-wow-delay=".4s"
              style={fadeInRigthStyle}
            >
              <input
                type="text"
                name="subs-email"
                id="subs-email"
                placeholder="Inserisci La Tua Email"
              />
              <button type="submit">
              <span className="material-icons">email</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
