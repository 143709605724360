import Features from "../components/Home/Features";
import Hero from "../components/Home/Hero";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Downbar from "../components/down_footer_pulizie";
import Card from "../components/Card";
import { Helmet } from "react-helmet"
import * as React from "react";
import "../styles/main.css";
import { pageStyles } from "../styles/style";
import { StaticImage } from "gatsby-plugin-image";


// manutenpul styles
// data
// cards services

const services = [
  {
    'image': <StaticImage src="../images/services/pulizie_uffici.webp" alt="SERVIZI DI PULIZIA" />,
    'title':'SERVIZI DI PULIZIA',
    'text':'Effettuiamo Pulizie Professionali- Pulizie Uffici, Negozi, Palestre, Studi.- Pulizie Condominiali.- Pulizie in Quota fino a 20 mt.- Sanificazione Bagni, Cucine, Mense. - Pulizie Post-Ristrutturazione.- Pulizie Strutture Sanitarie.',
  },{
    'image':<StaticImage src="../images/services/sanificazione.webp" alt="SANIFICAZIONE" />,
    'title':'SANIFICAZIONE',
    'text':'Eseguiamo servizi di sanificazione per qualsiasi ambiente civile ed industriale, a Milano e provincia (anche contro Coronvirus-COVID 19). Non è sufficiente igienizzare, ma occorre sanificare e, quindi, abbattere le cariche microbiche per ridurre il rischio di insorgenza e diffusione di patologie ed infezioni.',
  },{
    'image':<StaticImage src="../images/services/disinfestazioni.webp" alt="DISINFESTAZIONI" />,
    'title':'DISINFESTAZIONI',
    'text':'L’esperienza maturata ci permette oggi, di poter risolvere ogni genere di minaccia accompagnandovi nelle varie fasi di intervento fino alla completa estinzione dell’infestazione. Rivolgiamo i nostri servizi di disinfestazione sia ad utenti privati che a strutture pubbliche e tutti gli interventi sono svolti in rispetto del protocollo HACCP e tutti i prodotti sono presidi medico chirurgici e dotati di scheda tecnica e sicurezza.',
  },{
    'image':<StaticImage src="../images/services/giardinaggio.webp" alt="GIARDINAGGIO" />,
    'title':'GIARDINAGGIO',
    'text':'Un team di giardinieri si occupa di proteggere e preservare le aree verdi di immobili privati e pubblici.',
  },{
    'image':<StaticImage src="../images/services/furgon-1.webp" alt="SGOMBERI TRASLOCHI" />,
    'title':'SGOMBERI TRASLOCHI',
    'text':'Si eseguono con puntualità e professionalità sgomberi per privati: cantine, solai, depositi, appartamenti e anche per uffici o attività commerciali.',
  },{
    'image':<StaticImage src="../images/services/servizi-edili.webp" alt="SERVIZI EDILI" />,
    'title':'SERVIZI EDILI',
    'text':"Si effettuano ristrutturazioni edilizie complete per abitazioni residenziali, appartamenti e locali commerciali. Il team dell'impresa si occupa di ogni fase della ristrutturazione.",
  }
];

const icons_styles = {
  'pulizia_span':{
    'fontSize':'100px',
    'color':'red',
  },
  'pulizia_p':{
    'fontSize':'20px',
    'color':'red',
    'display':'contents',
  },
  'sanificazioni_span':{
    'fontSize':'100px',
    'color':'blue',
  },
  'sanificazioni_p':{
    'fontSize':'20px',
    'color':'blue',
    'display':'contents',
  },
  'pavimentaizioni_span':{
    'fontSize':'100px',
    'color':'green',
  },
  'pavimentaizioni_p':{
    'fontSize':'20px',
    'color':'green',
  },
  'integrati_span':{
    'fontSize':'100px',
    'color':'purple',
  },
  'integrati_p':{
    'fontSize':'20px',
    'color':'purple',
    'display':'contents',
  },
};


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet
          htmlAttributes={{
            lang: 'it',
          }}
          >
          <meta charSet="utf-8" />
          <title>MANUTENPUL » Impresa di Pulizie a Milano e Provincia</title>
          <meta name="description" content="Impresa di pulizie Milano: MANUTENPUL dal 1990. Affidabilità e tempestività contraddistinguno i nostri interventi di pulizia e sanificazione. Preventivo GRATUITO! Impresa di Pulizie Milano - Servizi di Pulizie Milano - Pulizie Professionali Milano"></meta>
          <meta name="keywords" content="impresa di pulizie milano,  impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi, Pulizia uffici, pulizie uffici, pulizie industriali, pulizie civili, pulizie condomini, pulizie uffici cesano boscone, pulizia uffici cesano boscone, pulizie cesano boscone, impresa di pulizie cesano boscone, impresa di pulizie bareggio, impresa di pulizie trezzano sul naviglio, impresa di pulizie cusago, impresa di pulizie, pulizie condominiali, preventivo pulizie"></meta>
          <meta property="og:tittle" content="MANUTENPUL Pulizie e Servizi Milano | Impresa di Pulizie a Milano e Privincie"></meta>
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Hero></Hero>
      <section className="feature-section pt-40" style={{'textAlign':'center'}}>
        <div className="container">
          <div className="row align-items-center">
              <div className="col-lg-3 pb-40 col-6">
                  <span className="material-icons" style={icons_styles['pulizia_span']}>cleaning_services</span>
                  <br />
                  <p style={icons_styles['pulizia_p']}>Servizi di pulizia</p>
              </div>
              <div className="col-lg-3 pb-40 col-6">
                  <span className="material-icons" style={icons_styles['sanificazioni_span']}>sanitizer</span>
                  <br />
                  <p style={icons_styles['sanificazioni_p']}>Sanificazioni</p>
              </div>
              <div className="col-lg-3 pb-40  col-6">
                  <span className="material-icons" style={icons_styles['pavimentaizioni_span']}>settings_suggest</span>
                  <br />
                  <p style={icons_styles['pavimentaizioni_p']}>Trattamenti</p>
                  <p style={icons_styles['pavimentaizioni_p']}>Pavimentazioni</p>
              </div>
              <div className="col-lg-3 pb-40 col-6">
                  <span className="material-icons" style={icons_styles['integrati_span']}>local_laundry_service</span>
                  <br />
                  <p style={icons_styles['integrati_p']}>Servizi Integrati</p>
              </div>
          </div>
        </div>
      </section>
      <Features></Features>
      <section id="blog" className="blog-section pt-90 pb-80">
        <div className="container">
        {/* <div className="row align-items-end"></div> */}
          <div className="row">
            {services.map(service => (
              <Card
              key={service.title}
              image={service.image}
              title={service.title}
              text={service.text} />
            ))}
          </div>
        </div>
      </section>
      <Subscribe />
      <Footer></Footer>
    </main>
  )
}

export default IndexPage;